// local('Montserrat') is require before every url() otherwise the rule "font-weight" does not work

/* Montserrat Thin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: local('Montserrat Thin'), url('fonts/Montserrat-Thin.otf') format('opentype');
}

/* Montserrat Extra Light */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: local('Montserrat Extra Light'), url('fonts/Montserrat-ExtraLight.otf') format('opentype');
}

/* Montserrat Light */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Montserrat Light'), url('fonts/Montserrat-Light.otf') format('opentype');
}

/* Montserrat Regular */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Montserrat Regular'), url('fonts/Montserrat-Regular.otf') format('opentype');
}

/* Montserrat Medium */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Montserrat Medium'), url('fonts/Montserrat-Medium.otf') format('opentype');
}

/* Montserrat Semi Bold */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Montserrat SemiBold'), url('fonts/Montserrat-SemiBold.otf') format('opentype');
}

/* Montserrat Bold */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Montserrat Bold'), url('fonts/Montserrat-Bold.otf') format('opentype');
}

/* Montserrat Extra Bold */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: local('Montserrat ExtraBold'), url('fonts/Montserrat-ExtraBold.otf') format('opentype');
}

/* Montserrat Black */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local('Montserrat Black'), url('fonts/Montserrat-Black.otf') format('opentype');
}
