div.field ~ div.field {
	margin-top: 10px;
}

.square-field {
	background-color: $noInteractionColor;
	margin: 10px;
	padding: 20px;
	min-width: 100px;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	border-radius: $criterionBorderRadius;

	.square-field-title {
		margin-bottom: 3px;
		font-weight: bold;
	}

	.square-field-content {
		font-size: 24px;
		color: black;
	}
}

.square-field.is-colored {
	background-color: $mainColor;
	color: white;

	.square-field-title {
		color: white;
	}

	.square-field-content {
		color: white;
	}
}
