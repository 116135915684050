.spinner {
	width: 40px;
	height: 40px;

	position: relative;
	margin: 100px auto;
}

.spinner-text {
	color: $mainColor;
	font-weight: 600;
}

.double-bounce1, .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: $mainColor;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;

	&.is-white {
		background-color: white;
	}
}

.double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
