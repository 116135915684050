// container for desktop and mobile top navbars
div.navbar-container {
	margin-bottom: 0 !important;
	//background-image: linear-gradient(180deg, $mainColor, $gradientColor);
	background: $mainColor;
	position: fixed;
	width: 100%;
	opacity: 1;
	z-index: 150;
	//border-bottom: 1px solid $focusColor;
	//box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
}

// desktop/tablet top navbar
nav.large-navbar {
	display: none;
	justify-content: space-around;
	margin: 0;
	background: $mainColor;
	height: 50px;
	padding: 10px;

	@media (min-width: $tablet) {
		display: flex;
		padding: 20px;
	}

	.navbar-brand {
		display: flex;
		align-items: center;
		max-width: 20%; // adapt size of logo brand for small desktop screen

		span {
			color: white !important;
			font-weight: 400;
		}

		span strong {
			font-weight: 900;
		}
	}

	.navbar-items {
		display: flex;
		align-items: center;
		flex-shrink: 1;
		flex-grow: 1;
		justify-content: flex-end;
		gap: 7px;

		@media (min-width: $desktop) {
			flex-grow: 0;
			margin-left: 10px;
		}
	}

	.navbar-item.always-colored {
		color: black;
		font-weight: bold;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;

		&:hover {
			color: $mainColor;
			font-weight: 600;
			background-color: white;
		}

		&.is-active {
			color: $mainColor;
			background-color: white !important;
			font-weight: 600;
		}
	}

	.navbar-item {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: white;
		background-color: transparent;
		padding: 10px;
		font-weight: 600;
		//letter-spacing: -0.5px;

		&:hover {
			color: white;
			//font-weight:600;
			background-color: $lightBlue;
			color: black;
		}

		&:focus {
			outline: none;
		}

		&.is-active {
			color: white;
			background-color: $lightBlue !important;
			color: black;
			//font-weight:600;
		}

		svg {
			font-size: 20px;
		}
	}

	.alert-selector-in-navbar {
		display: none;
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;
		max-width: 500px;

		@media (min-width: $widescreen) {
			display: flex;
		}
	}
}

// top mobile navbar
nav.small-navbar {
	background-color: $mainColor;
	height: 50px;

	img {
		max-height: 40px;
	}

	div.flex-centered-left {
		display: flex;
		justify-content: left;
		align-items: center;
		padding-left: 5px;
	}

	div.flex-centered-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-right: 5px;
	}
}

// bottom mobile navbar
.mobile-navbar {
	position: fixed;
	bottom: 0;
	background: $mainColor;
	z-index: 150;
	width: 100%;

	ul {
		border: 0;
		width: 100%;
		height: 50px;
	}

	li {
		width: 20%; //since we have 5 elements in the bottom bar
		height: 100%;
		padding: 4px;
	}

	a {
		border: 0;
		height: 100%;
		flex-direction: column;

		&:hover {
			color: $focusColor !important;
		}

		span {
			font-size: 8px;
			margin: 0;
			padding: 0;
		}
	}

	a.navbar-item {
		color: white;
		font-size: 22px;
		margin-bottom: 0;
		line-height: 0.25;

		&.is-active {
			color: black;
			border-bottom-color: $focusColor;
			background-color: $lightBlue !important;
		}
	}
}

// mobile search navbar
.button.map-displayed {
	width: auto !important;
}

// desktop toolbox and mobile/tablet for toolbox location map
div.toolbox {
	display: none;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 25px;
	z-index: 32;
	background-color: $lightBlue;
	border-radius: 10px;
	padding: 10px;
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		
	@media (min-width: $tablet) {
		display: flex;	
	}

	&.mobile {
		display: flex;
		background-color: transparent;
		box-shadow: none;
		bottom: 50px;
	}	

	.button {
		color: white;
		border-radius: 999px;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: 5px;
		margin-right: 5px;
		width: 0;
		border: 0;
		background-color: $focusColor;

		&.large-button {
			width: auto;
		}
	}
}

@keyframes blinker {
	from {
		opacity: 1.0;
	}

	to {
		opacity: 0.0;
	}
}

.yellow-blink {
	text-decoration: blink;
	color: $yellowMI !important;

	animation-name: blinker;
	animation-duration: 0.6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-direction: alternate;

	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.6s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-direction: alternate;
}

.icon-logout {
	color: white;
    transition: color 0.1s ease;
	cursor: pointer;
	margin-left: 5px;
	width: 40px;
	height: 40px;

	&:hover {
		color: $yellowMI;
	}
}

.navbar-logout-button {
	color: white;
	transition: color 0.1s ease;
	cursor: pointer;
	margin-left: 5px;
	height: 30px;
	width: 30px;

	&:hover {
		color: $yellowMI;
	}
}
