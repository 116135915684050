.kanban-container {
	display: flex;
	flex-direction: row;
	padding: 0;
	overflow-x: auto;

	@media (min-width: $desktop) {
		max-width: calc(100% - 50px);
		margin-left: 25px;
		margin-right: 25px;
	}
}

.kanban-column-head {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: $white;
	color: $mainColor;
	font-size: larger;
	padding: 10px;
	margin-right: 10px; // compensate kanban column 0px on the right (for column y-scroll)
	border-radius: 10px;
	font-weight: 700;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
	cursor: not-allowed;

	&.editable {
		cursor: grab; // "grab" is not supported by FF on Linux (bug)
	}
}

div.draggable {
	margin: 0 10px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&.is-dragging {
		margin: 0 20px;
	}
}

.kanban-column {
	display: flex;
	flex-direction: column;
	padding: 10px 0px 10px 10px;
	border-radius: 8px;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.editable-icons {
	display: flex;
	flex-wrap: nowrap;
}

.kanban-column-ads {
	min-height: 400px;
	padding: 8px 18px 8px 8px; // we add 10px on the right (take on kanban-column) to position the scroll bar
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 1000px;

	/* For Firefox only */
	scrollbar-color: #aaa transparent;
}

// custom scroll bar
.kanban-column-ads::-webkit-scrollbar {
	width: 8px;
}

.kanban-column-ads::-webkit-scrollbar-track {
	// background of the scroll bar
	background: transparent;
}

.kanban-column-ads::-webkit-scrollbar-thumb {
	// scroll bar (the part who moves)
	background-color: #aaa;
	border-radius: 10px;
	border: 2px solid #f0f0f0;
}

.kanban-column-ads:active {
	cursor: grabbing;
}

.ad-draggable {
	cursor: grabbing;
}

.ad-draggable:active {
	cursor: grabbing;
}

.input-custom-label {
	min-width: 250px;
	font-size: 1em;
	border-radius: 10px;
	border: 1px solid #ccc;
	padding: 10px;
	outline: none;
	box-shadow: 0 0 5px $mainColor;
	transition: box-shadow 0.3s ease;

	&:focus {
		box-shadow: 0 0 8px $focusColor;
	}
}

.color-picker-container {
	position: absolute;
	top: 35px;
	left: 0;
	z-index: 20;
}

.color-selector {
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	cursor: pointer;
	margin-right: 5px;
}

.mini-selector-button {
	cursor: pointer;
	transition: color 0.3s ease;
	margin-left: 10px;

	&.check {
		color: white;

		&:hover {
			color: $yellowMI;
		}
	}

	&.times {
		color: white;
		margin-right: 10px;

		&:hover {
			color: $redMI;
		}
	}
}

.selector-button {
	cursor: pointer;
	transition: color 0.3s ease;
	color: $mainColor;
	margin-left: 10px;

	&:hover {
		color: $focusColor;
	}
}
