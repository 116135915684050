.carousel-container {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 56.25%;
	overflow: hidden;

	// disable blue selection of element or picture when clicking on next or prev button
	* {
		-webkit-user-select: none; // Safari
		-moz-user-select: none; // Firefox
		-ms-user-select: none; // Edge
		user-select: none; // other browsers
	}

	.carousel-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	.carousel-button {
		position: absolute !important;
		top: 45%;
		z-index: 2;
		cursor: pointer;

		&.left {
			left: 10px;
		}

		&.right {
			right: 10px;
		}

		.carousel-button-icon {
			width: 18px;
			height: 18px;
			padding: 10px;
			color: black;
			background-color: white;
			border-radius: 50%;
			transition: transform 0.3s ease;
			box-shadow: 0px 0px 10px black;

			@media (min-width: $tablet) {
				width: 20px;
				height: 20px;
			}

			&:hover:not(.disabled) {
				transform: scale(1.1);
			}

			&.disabled {
				opacity: 0.3;
				cursor: not-allowed;
				box-shadow: none;
			}
		}
	}
}
