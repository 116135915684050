div.saved-ads-panel,
div.saved-searches-panel {
	padding: 10px;
	overflow: hidden;
	border: 0;
	box-shadow: none;
}

div.account-panel {
	display: flex;
	justify-content: flex-start;
	margin-top: 10px;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
	border-radius: 20px;
	background-color: white;

	@media (min-width: $tablet) {
		justify-content: center;
		margin-top: 60px;
	}

	h3.account-panel-title {
		font-size: 20px;
		font-weight: 800;
		padding: 10px;
		margin-bottom: 10px;
		color: #363636;

		&.center {
			text-align: center;
		}
	}

	label.label {
		text-align: center;
	}

	div.centered-panel-content {
		text-align: center;
		margin-bottom: 30px;
		width: 100%;

		&.w400px {
			max-width: 400px;
		}

		&.w600px {
			max-width: 600px;
			padding: 10px;

			@media (min-width: $tablet) {
				padding: 0;
			}
		}
	}

	div.account-panel-section {
		width: 100%;
		margin-bottom: 30px;


		&.webhook-box {
			background-color: #f0f5ff;
			padding: 20px;
			border-radius: 10px;
			box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
		}
	}
}

.account-parameters-panel,
.account-tools-panel,
.api-testing-panel {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media (min-width: $tablet) {
		flex-direction: row;
	}

	.left-tab-menu {
		display: flex;
		min-width: 100px;
		background-color: $noInteractionColor;
		border-radius: 10px 0px 0px 10px;
		overflow: hidden;

		@media (min-width: $tablet) {
			min-width: 200px;
		}

		ul {
			width: 100%;
		}

		.left-tab-button {
			display: flex;
			width: 100%;
			border: none;
			padding: 10px;
			cursor: pointer;
			background-color: $noInteractionColor;
			font-weight: 500;
			text-align: left;
		}

		.left-tab-button:hover {
			background-color: $lightBlue;
		}

		.left-tab-button:focus,
		.left-tab-button.is-active {
			background-color: $mainColor;
			color: white;
			font-weight: 600;
		}
	}

	.right-content {
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px;
		background-color: white;
		border-radius: 0px 10px 10px 0px;

		div.account-parameters-checkbox {
			margin-bottom: 0;
			display: flex;
			justify-content: center;
			margin-top: 10px;
		}

		div.logout-delete-buttons {
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}
	}

	.right-content>div {
		width: 100%;
	}
}

.referring-panel,
.user-plan-panel {
	width: 100%;
	padding: 0;

	@media (min-width: $tablet) {
		padding: 10px;
	}

	.tag {
		font-weight: 500;
		border-radius: 10px;

		&.is-success {
			color: white;
		}
	}

	.label {
		margin-bottom: 0;
		text-align: left !important;
	}
}

.account-panels-navbar {
	display: flex;
	justify-content: center;

	.tabs-container {
		width: 100%;
		margin-top: 20px;
		padding-top: 5px;
		padding-bottom: 5px;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 10px;
		z-index: 5;
		background-color: transparent;

		@media (min-width: $tablet) {
			background-color: white;
			position: fixed;
			top: 64px;
			margin-top: 0px;
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
		}
	}

	.tab {
		padding: 5px;
		margin: 0;
		font-size: 1rem;
		padding: 10px;
		border: none;
		background: none;
		cursor: pointer;
		font-weight: 600;
		z-index: 2;
		background-color: white;
		border-radius: 15px;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);

		@media (min-width: $tablet) {
			background-color: transparent;
			border-radius: none;
			box-shadow: none;
		}

		&.active {
			color: white;
			font-weight: 700;
			background-color: $mainColor;

			@media (min-width: $tablet) {
				background-color: transparent;
				color: black;
			}
		}

		&.hidden {
			display: none;
		}
	}

	.tab.logout {
		color: $redMI;
	}

	.tab.logout:hover {
		background-color: $redMI;
		color: white
	}

	.slider {
		position: absolute;
		bottom: 2px;
		height: 2px;

		background-color: transparent;
		transition: left 0.3s ease, top 0.3s ease, width 0.3s ease;

		@media (min-width: $tablet) {
			background-color: $mainColor;
		}
	}
}

.property-small-list {
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 0px 3px 5px #00000029;
	height: 400px;
	background-color: white;
}

.property-small-list-inner,
.saved-search-panel-content-inner {
	height: 100%;
	overflow: auto;
	background-color: white;
}

.saved-search-panel-content {
	border-radius: 20px;
	background-color: white;
	box-shadow: 0px 3px 5px #00000029;
	overflow: hidden;
}

.saved-search-panel-content-mobile {
	border-radius: 20px;
	background-color: white;
	box-shadow: 0px 3px 5px #00000029;
	overflow: hidden;
}

.panel-head {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.desktop-upper-panels {
	display: flex;
	flex-direction: row;

	.saved-search {
		flex-grow: 1;
		padding: 10px;
	}
}
