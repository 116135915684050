.notification-container {
	position: fixed;
	bottom: 50px;
	padding: 0;
	width: 100%;
	z-index:50;

	@media (min-width: $tablet) {
		bottom: 0;
		padding: 10px;
	}

	.notification {
		margin: auto;
		text-align: center;
		border-radius: 0;
		padding: 15px;

		&.hidden {
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s 1s, opacity 1s linear;
		}

		@media (min-width: $tablet) {
			max-width: 600px;
			border-radius: 5px;
			padding: 30px;
		}
	}
}

.notification.is-info.is-light {
	background-color: $lightBlue !important;
	color: black;
	border-radius: 10px;
	margin:10px;
	padding: 5px;
}
