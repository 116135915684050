footer.footer {
	display: none;
	padding: 0px 0;
	color: white;
	margin-top: 0;
	background-color: $mainColor;
	position: relative;
	font-weight: 600;
	z-index: 50;

	a:not(:hover) {
		color: white;
	}

	a:hover {
		color: $yellowMI
	}

	.follow-us {
		margin-top: 0;
		padding: 60px;
		background-color: $noInteractionColor;

		h4 {
			font-weight: 900;
		}
	}

	.footer-infos {
		padding: 10px;
	}

	@media (min-width: $tablet) {
		display: block;
		margin-top: 50px;
	}
}
