[data-tooltip]:hover:before {
	font-size: 12px;
	padding: 5px;
	box-shadow: 0px 5px 8px #00000029;
	background-color: white;
	color: $mainColor;
	border-radius: 10px;
	border: 1px solid $mainColor;
	z-index: 99;
	font-weight: normal;
}

[data-tooltip].large:hover:before {
	font-size: 20px;
}

[data-tooltip]:before {
	-webkit-hyphens: none !important;
	-moz-hyphens: none !important;
	-ms-hyphens: none !important;
	hyphens: none !important;
	text-align: center;
}

[data-tooltip]:after {
	display: none !important;
}

[data-tooltip].tag {
	cursor: default;
}

[data-tooltip].has-tooltip-bottom-left:hover:before {
	transform: translate(-100%, 150%);
}

[data-tooltip].has-tooltip-bottom-right:hover:before {
	transform: translate(0%, 150%);
}

[data-tooltip].has-tooltip-top-right:hover:before {
	transform: translate(0%, -150%);
}

[data-tooltip].has-tooltip-slightly-top-right:hover:before {
	transform: translate(0%, -50%);
}

[data-tooltip].has-tooltip-slightly-top-left:hover:before {
	transform: translate(-100%, -120%);
}

[data-tooltip]:focus:not(:hover):before {
	visibility: hidden;
}
