.features-box {
	display: grid;
	grid-gap: 20px;
	max-height: 650px;
	overflow-y: auto;
	padding: 20px;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background: white;
	margin: 10px;
	grid-template-columns: 1fr; // mobile

	@media (min-width: $tablet) {
		margin: 0;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: $desktop) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.feature-card {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: auto;
	min-width: 0
}

.card-description {
	display: flex;
	height: 100%;
	padding: 10px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	border: 2px solid #007bff;

	&.on-ballot {
		background-color: $veryLightBlue;
		border: none;
	}

	&.disapproved {
		border: 2px solid $redMI;
	}
}

.disapproved {
	color: $redMI !important;
}

.button.vote {
	font-size: 14px;
	border: None;
	border-radius: 8px;

	&.primary {
		background-color: $mainColor;
		color: white;
	}

	&.secondary {
		background-color: $lightBlue;
	}

	&.tertiary {
		background-color: white;
		color: black;
		border: 1px solid $lightBlue;
	}

	&:hover:not(:disabled) {
		background-color: $focusColor !important;
		color: white;
	}
}

.coming-soon {
	background-color: $veryLightBlue;
	font-weight: 800;
	padding: 10px;
	border-radius: 10px;
}

.disapproval {
	background-color: $redMI;
	color: white;
	font-weight: 800;
	padding: 10px;
	border-radius: 10px;
}