.toggle-switch {
	display: flex;
	border-radius: $borderRadius;
	max-width: 100%;
	
  }
  
  button.toggle-button {
	padding: 10px;
	border: none;
	cursor: pointer;
	background-color: white;
	transition: background-color 0.3s ease;
	box-sizing: border-box;

	&:not(.last) {
		border-right: 1px solid #e0e0e0;
	  }

	&.has-border {
		box-shadow: 
        0 2px 0 0 $mainColor, // Simulate border-top (to be above border-right)
        0 -2px 0 0 $mainColor; // Simulate  border-bottom(to be above border-right)
	}
  
	&.first {
	  border-top-left-radius: $borderRadius;
	  border-bottom-left-radius: $borderRadius;
	  &.has-border{
	  	border-left: 2px solid $mainColor;
	  }
	}
  
	&.last {
	  border-top-right-radius: $borderRadius;
	  border-bottom-right-radius: $borderRadius;
	  &.has-border{
	  	border-right: 2px solid $mainColor;
	  }
	}

  
	&.active {
	  background-color: $mainColor;
	  color: white;
	}
  
	&.disabled {
	  cursor: not-allowed !important;
	  opacity: 0.5;
	}
  }

  .title-with-tooltip {
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (min-width: $tablet) {
		width:auto;

	}
  }
  
  
  // Specific for classique/intelligente/inversée switch
  div.search-type {
	button.toggle-button {
	  font-weight: 600;
  
	  &:not(.active) {
		color: #4a4a4a;
	  }
	}
  }
  