$mandatory: #707070;
$noActiveColumn : #f7f7f7;
$suggestionItems : #f1f1f1;
$scrollBar : #c1c1c1;

//select
.criterion-enum-select,
.min-input,
.max-input {
	flex-grow: 1;
	min-width: 150px;
}

.search-submit-button {
	padding: 15px;
	margin-bottom: 10px;
}

.tag.is-pro {
	//padding : 5px;
	background-color: $yellowMI;
	margin: 0px 5px;
	font-size: 0.7em;
	font-style: normal !important;

	&::before {
		font-size: 14px !important;
	}
}

div.search-form-container {
	h1 {
		padding-top: 10px;
		padding-left: 10px;
		font-size: 1.2em;
		font-weight: 700;
		letter-spacing: 0;
		color: white;

		span {
			display: inline;
		}
	}

	div.advanced-search-form-complete {
		background-color: grey;
		background-size: cover;
		background-position: center;
		border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px;
		display: block;
		width: 100%;
		margin-top: 0;
		position: relative;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
		padding-bottom: 10px;
		border-radius: 0px 0px 50px 50px;
	}
}

.advanced-search-form {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;

	@media (min-width: $tablet) {
		margin: 0 auto;
		width: 80%;
	}

	.header-search-form {
		padding: 20px 0px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		justify-content: center;

	}

	.criterion-type-and-categories {
		display: flex;
		flex-wrap: no-wrap;
		gap: 10px;
	}

	.only-areas {
		background-color: $mainColor;
		color: white;
		border-radius: 10px;
		border-color: 'white';

		&:hover:not(:disabled) {
			background-color: white;
			color: $mainColor;
			border-color: $mainColor;
		}

		&:disabled {
			background-color: $lightBlue;
		}

		&.error {
			background-color: $redMI;
			color: white;

			&:hover:not(:disabled) {
				background-color: $focusRed;
				border-color: white;
				color: white;
			}
		}
	}

	.criterion-location {
		// flex: 2;
		display: flex;
		flex-wrap: no-wrap;
		gap: 10px;

		.location-input-div {
			flex: 4;
		}

		.location-radius-div {
			flex: 1;
		}

		.location-titles {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	#map-button,
	#excluded-switch {
		font-weight: 600;
		padding: 5px;
	}

	.dividers {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;

		.divider {
			margin-right: 5px;
			margin-top: auto;
			border: none;
			background-color: $mainColor;
			color: white;
			border-radius: 10px 10px 0px 0px;

			font-size: 0.9rem;
			cursor: pointer;
			transition: background 0.3s;
			//min-width: 100px;

			&:hover:not(:disabled) {
				background-color: $yellowMI !important;
				color: black;
				font-weight: 600;
			}

			&:disabled {
				background-color: $lightBlue !important;
			}
		}

		#map-button {
			margin-right: 15px;
		}
	}

	div.criteria-search-bar-container {
		display: flex;
		width: 100%;
	}

	div.search-container {
		width: 100%;
		position: relative;
		border-radius: 10px;
		// padding-left : 10px;
		// padding-right : 10px;
		flex-direction: column;
	}

	.criteria-search-bar {
		display: flex;
		flex-direction: row;
		gap: 10px;
		padding: 0px;
		border-radius: $borderRadius;
		overflow: hidden;
		margin-bottom: 10px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);


		.selected-criterion-input {
			display: flex;
			flex-wrap: wrap; // Allow multi-lines element display
			background: $noInteractionColor;
			width: 100%;
			height: 100%;
			border-radius: $borderRadius;
			overflow: hidden;

			@media (min-width: $desktop) {
				flex-wrap: nowrap;
			}

			.selected-criterion {
				display: flex;
				overflow: hidden;
				padding: 10px;
				min-width: 100px;
				font-weight: bold;
				justify-content: center;
				flex: 0 0 88%;

				@media (min-width: $desktop) {
					flex: none;
				}
			}

			.error-message {
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				background-color: $redMI;
				align-self: stretch;
			}

			.reset-selection {
				padding-left: 5px;
				padding-right: 5px;
				background-color: transparentize($darkGrey, 0.2);
				font-family: $body-family;
				//font-weight: bold;
				font-size: x-large;
				border: none;
				cursor: pointer;
				flex: 0 0 12%;

				@media (min-width: $desktop) {
					flex: none;
				}

				&:hover {
					background-color: $redMI;
					color: white;
				}
			}

			.success-placeholder {
				color: $mainColor;
				font-weight: 700;
			}

			.min-max-input,
			.min-input,
			.max-input {
				box-sizing: border-box;
			}

			input {
				flex: 1;
				display: flex;
				border: none;
				padding: 10px;
				font-size: 1rem;
			}

			.min-max-input {
				display: flex;
				flex: 0 0 100%;

				@media (min-width: $desktop) {
					flex: 1;
				}

				.max-input,
				.min-input {
					text-align: center;
					flex: 1;

					.min-max-separator {
						background-color: white;
					}
				}
			}

			.criterion-unit {
				display: flex;
				align-items: center;
				padding-right: 10px;
				background: white;
			}

			.add-criterion {
				padding: 10px;
				border: none;
				background: $noInteractionColor;
				color: black;
				margin-left: auto;
				font-size: 1rem;
				cursor: pointer;
				transition: background 0.3s;
				min-width: 100px;
				flex: 0 0 100%;

				@media (min-width: $desktop) {
					flex: none;
				}

				&:hover {
					background: $mainColor;
					color: white;
				}
			}
		}
	}

	.criteria-list-div {
		background: transparent;
		padding: 10px 0px 20px;
		display: flex;
		gap: 10px;
		border-radius: $borderRadius;
		align-items: top;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.column {
			flex: 0 0 calc(50% - 5px);
			margin-bottom: 10px;

			@media (min-width: $tablet) {
				flex: 1;
				margin-bottom: 0;
			}
		}

		.hover-warning {
			position: relative;
			display: inline-block;

			&:hover::after {
				content: attr(title);
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				background-color: $yellowMI;
				color: black;
				text-align: center;
				padding: 5px 10px;
				border-radius: 6px;
				width: max-content;
				max-width: 200px;
				word-wrap: break-word;
				z-index: 1;
				border: none;
				font-size: 0.8rem;
			}
		}

		.single-column-criteria {
			display: flex;
			flex-direction: row;
			gap: 5px;
			flex-wrap: wrap;
			align-items: center;
			cursor: pointer;
		}

		@keyframes highlight {
			from {
				background-color: $yellowMI;
			}

			to {
				background-color: $noInteractionColor;
			}
		}

		.highlight-criterion {
			animation: highlight 1s;
		}

		.criterion {
			background: $noInteractionColor;
			padding: 5px 5px;
			border-radius: $criterionBorderRadius;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			font-size: 0.8rem;

			@media (min-width: $tablet) {
				font-size: 0.9rem;
			}

			.delete-criterion {
				padding-left: 0.3rem;
				cursor: pointer;

				@media (min-width: $tablet) {
					padding-left: 0.5rem;
				}

				&:hover {
					color: $redMI;
				}
			}

			&.disable-criterion {
				background-color: $redMI;
				text-decoration: line-through;
			}
		}

		.column {
			background-color: $noActiveColumn;
			border-radius: $borderRadius;
			padding: 10px;
			min-height: 175px;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

			&.mandatory {
				border-color: $mandatory;

				.column-header {
					background-color: $mandatory;
				}
			}

			&.important {
				border-color: transparentize($mainColor, 0.0);

				.column-header {
					background-color: transparentize($mainColor, 0.0);
				}
			}

			&.desired {
				border-color: transparentize($mainColor, 0.2);

				.column-header {
					background-color: transparentize($mainColor, 0.2);
				}
			}

			&.optional {
				border-color: transparentize($mainColor, 0.4);

				.column-header {
					background-color: transparentize($mainColor, 0.4);
				}
			}

			&.active {
				background-color: white;
				transition: box-shadow 0.3s ease, color 0.3s ease;
				box-shadow: 0px 0px 10px 3px rgba(144, 183, 255, 0.705);

				.column-header {
					color: white;
					font-weight: bold;
				}
			}

			.column-header {
				color: $noActiveColumn;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				text-align: center;
				border-top-left-radius: $borderRadius;
				border-top-right-radius: $borderRadius;
				padding: 10px 0;
				margin: -10px -10px 10px; // To overlap the column border
				position: relative;
				//z-index: 1;
				font-size: 1.1rem;
			}

			.column-criteria {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}

	.suggestions-list {
		max-width: 100%;
		border-radius: $borderRadius;
		overflow-y: auto;
		overflow-x: hidden;
		background-color: white;
		position: absolute;
		z-index: 12 !important;
		width: 99%;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
		padding: 0px 10px;
		justify-items: start;
		justify-content: start;

		// center
		left: 50%;
		transform: translateX(-50%);
	}

	.suggestions-list::-webkit-scrollbar {
		width: 10px;
	}

	.suggestions-list::-webkit-scrollbar-track {
		border-radius: 20px;
	}

	.suggestions-list::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: $scrollBar;
	}

	.suggestions-list::-webkit-scrollbar-thumb:hover {
		background: $mainColor;
	}

	.classic-criteria,
	.options,
	.keywords {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
		gap: 8px;
	}

	.classic-criteria-container {
		padding: 5px;
	}

	.options-container {
		padding: 5px;
	}

	.keywords-container {
		padding: 5px;
	}

	.keywords {
		span {
			padding-left: 10px;
			font-style: italic;

			&.is-danger {
				color: $redMI;
			}
		}
	}

	.suggestion-item {
		background-color: $suggestionItems;
		padding: 5px 5px;
		white-space: nowrap;
		cursor: pointer;
		font-size: 0.9rem;
		flex-direction: column;
		border-radius: $criterionBorderRadius;

		&.hover,
		&.selected {
			background-color: $mainColor;
			color: white;
		}
	}

	.suggestion-item.disabled {
		background-color: $suggestionItems;
		color: $darkGrey;

		&.hover,
		&.selected {
			background-color: $lightBlue;
			color: white;
			//font-weight: bold;
		}
	}
}

div.saved-ads-search-form {
	padding: 20px 5px 20px 5px;

	h4 {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;

		@media (min-width: $tablet) {
			margin-top: 0;
		}
	}

	div.field {
		margin-bottom: 0;
	}

	div.is-12-mobile {
		padding: 0 0 10px 0;
	}

	div.is-half-tablet,
	div.is-4-tablet,
	div.is-8-tablet {
		@media (min-width: $tablet) {
			padding: 0px 5px 10px 5px;
		}
	}

	div.is-half-desktop {
		@media (min-width: $desktop) {
			padding: 0px 5px 10px 5px;
		}
	}

	// for the last row
	div.is-one-quarter-desktop {
		@media (min-width: $desktop) {
			padding: 0;
		}
	}

	// for the last row
	div.is-half-tablet > div.is-half-tablet:first-child {
		@media (min-width: $tablet) {
			padding-left: 0;
		}
	}

	span.toggle-display-ads-filter {
		padding: 0;
		color: $mainColor;

		@media (min-width: $tablet) {
			padding: 10px;
			// margin-left: -20px;
		}
	
		&:hover {
			color: $focusColor;
		}
	}
}

.keyword-mode {
	margin-left: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
}
