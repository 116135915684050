.quotas-bar-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px 0 10px 0;
	width: 100%;

	.title {
		text-align: left;
		font-size: 0.9em;
		width: 100%;
		box-sizing: border-box;
		margin-bottom: 1px;
		margin-left: 10px;
	}

	.quotas-bar {
		width: 100%;
		background-color: $veryLightBlue;
		border-radius: 20px;
		overflow: hidden;
		box-sizing: border-box;
		position: relative;
		padding: 5px;

		.quotas {
			height: 15px;
			border-radius: 10px;
			transition: width 0.3s ease;
		}

		.quotas-value {
			position: absolute;
			width: 100%;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 30px;
			font-weight: bold;
			text-shadow:
				-1px -1px 0 white,
				1px -1px 0 white,
				-1px 1px 0 white,
				1px 1px 0 white;
		}
	}
}
