div.markdown-page {
	// display: grid; // is it useful ?

	pre {
		background-color: #f6f8fa;
		border-radius: 6px;
	}

	h1 {
		text-align: center;
	}

	h3 {
		margin-bottom: 5px;
	}

	h4 {
		margin-top: 30px;
		margin-bottom: 5px;
		padding: 0;
	}

	b {
		font-weight: 600;
	}

	ul {
		margin-top: 5px;
		margin-bottom: 5px !important;
	}

	p + ul, p + ol {
		margin-top: -15px;
	}

	h4 + ul, h4 + ol {
		margin-top: 0;
	}
}

// weird bug, the class name cannot start with "markdown"
div.mrkdown-page-picture {
	text-align: center;
	margin-bottom: 20px;

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
}

div.mrkdown-page-picture + ol {
	margin-top: 30px;
}
