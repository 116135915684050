.map-container {
	padding: 20px;
	z-index: 0 !important;
	border-radius: 20px;
	height: 650px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	position: relative;

	@media (max-height: 950px) {
		height: 500px;
	}

	@media (max-height: 850px) {
		height: 400px;
	}
}

.ads-map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: -17px;
	padding: 0 20px 20px;
}

.map-container.fullscreen {
	position: fixed;
	top: 50px; // To avoid superior and inferior navbar on mobile and tablet
	left: 0;
	width: 100%;
	height: calc(100% - 100px); // 2*50px for top and bottom on tablet and mobile
	z-index: 31 !important;
	padding: 0;
	border-radius: 0;
	margin-bottom: 0;
	box-shadow: none;
	overflow: visible;

	@media (min-width: $tablet) {
		top: 60px; // To avoid superior and inferior navbar on desktop
		height: calc(100% - 60px);
	}
}

.map-tool-box {
	display: 'flex';
	flex-direction: 'column';
	border-radius: 10px;
	height: 100%;
	background-color: white;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
	font-weight: 600;

	.edit-tools-div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		flex-wrap: nowrap;
		gap: 10px;
		margin-top: 5px;
		margin-bottom: 5px;

		@media (min-width: $tablet) {
			padding-right: 10px;
		}

		span {
			&.included {
				color: $mainColor,
			}

			&.excluded {
				color: $redMI;
			}
		}
	}

	.tool-additional-info {
		max-width: 500px;
		font-weight: 400;
		text-align: center;
		// border-right: 1px solid #e3e2e2;
		padding-right: 4px;
		font-size: 0.9em;
		font-style: italic;

		&.error {
			color: $redMI;
			background-color: white;
			border-radius: 0px;
			border-right: none;
		}
	}

	&.button {
		box-shadow: None;
		height: 40px;
		width: 40px;

		&.included {
			border-color: $mainColor;
			color: $mainColor;

			&:hover {
				background-color: #dde7fa;
			}
		}

		&.excluded {
			border-color: $redMI;
			color: $redMI;

			&:hover {
				background-color: #fde4e2;
			}
		}

		&.draw {
			color: white;

			&.included {
				background-color: $mainColor;
			}

			&.excluded {
				background-color: $redMI;
			}
		}

		&.validate,
		&.draw,
		&.cancel,
		&.edit {
			width: auto;
		}

		&.validate:hover,
		&.edit:hover {
			background-color: $mainColor;
			color: white;

			&.excluded {
				background-color: $redMI;
			}
		}

		&.cancel:hover {
			background-color: $redMI;
			color: white;
		}

		&.drawing {
			width: 60px;
		}

		&.validate.touch {
			color: white;

			&.included {
				background-color: $mainColor;
			}

			&.excluded {
				background-color: $redMI;
			}

		}
	}
}

.box-map-menu {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	position: fixed;
	bottom: 70px;
	justify-content: center;
	align-items: center;
	left: 50%;
	transform: translateX(-50%);
	z-index: 49;
	gap: 10px;

	@media (min-width: $tablet) {
		bottom: 120px;
	}

	&.draw-menu-mobile {
		bottom: 110px;
	}

	&.top {
		bottom: auto;
		top: 70px;

		@media (min-width: $tablet) {
			top: 80px;
		}
	}

	.box-map-menu-item {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
		background-color: $lightBlue !important;
		font-weight: 600;
		border-radius: 7px;
		border: none;
		color: #404040 !important;

		&:hover:not(:disabled) {
			background-image: none;
			background-color: $focusColor !important;
			color: white !important;
		}

		&:disabled {
			cursor: not-allowed;
		}
	}
}

.map-legend {
	position: fixed;
	display: flex;
	flex-direction: column;
	bottom: 60px;
	left: 15px;
	z-index: 49;

	.new-ad-legend {
		padding: 10px;
		color: black;
		background-color: $yellowMI;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
		border-radius: 7px;
		font-weight: 600;
		border: none;
	}
}

.map-spinner {
	z-index: 60;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;

	label {
		font-size: 1.1em;
		color: $mainColor;
	}
}

.map-marker {
	display: flex !important;
	align-items: center;
	justify-content: center;
	color: $mainColor;
	background-color: white;
	border-radius: 50%;
	font-family: $body-family;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	box-shadow: 0 3px 6px rgba(99, 99, 99, 0.16), 0 3px 6px rgba(196, 196, 196, 0.23);

	&.approximate-position {
		background-color: $noInteractionColor;
	}

	&.diagnostic-position {
		background-color: $greenMI;
		color: white;
	}

	&.approximate-position.new-ad {
		background-color: #f3d57ac0;
	}

	&.hidden-ad {
		background-color: #ffffffaf;
		color: $darkGrey;
	}

	&.new-ad {
		background-color: $yellowMI;
		color: black;
	}

	&.diagnostic-position.new-ad {
		background-color: #d9f37ac0;
	}


}

.map-marker-hoverinfo {
	position: absolute;
	background: white;
	border-radius: 8px;
	display: none;
	transform: translate(0, -75%);
	z-index: 50;
	overflow: hidden;

	.map-property-title {
		font-weight: bold;
		overflow: hidden;
		white-space: normal;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		max-height: 3.8em;
		border-radius: 0;
	}

	.new-ad {
		background-color: $yellowMI;
		color: black;
		display: inline;
		padding: 2px;
		border-radius: 5px;
	}

	.approximate-position {
		background-color: $noInteractionColor;
		color: black;
		display: inline;
		padding: 2px;
		border-radius: 5px;
	}

	.diagnostic-position {
		background-color: $greenMI;
		color: white;
		display: inline;
		padding: 2px;
		border-radius: 5px;
	}

	.hidden-ad {
		background-color: $darkGrey;
		color: white;
		display: inline;
		padding: 2px;
		border-radius: 5px;

	}
}

// LEAFLET PERSONALIZATION

.leaflet-control-zoom a {
	background: #fff;
	border: none;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.leaflet-control-zoom-in {
	border-top-left-radius: 7px !important;
	border-top-right-radius: 7px !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.leaflet-control-zoom-out {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 7px !important;
	border-bottom-right-radius: 7px !important;
}

.leaflet-control-zoom a:hover {
	background: $mainColor;
	color: white;
	border: none;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.leaflet-touch .leaflet-bar {
	border: none;
}

.leaflet-draw.leaflet-control {
	a {
		border-radius: 0;
	}

	a:hover {
		background-color: $mainColor;
		color: white;
	}
}

.map-label {
	border-radius: 8px !important;
	background-color: $mainColor;
	color: rgb(218, 218, 218);
	border: none !important;
	font-weight: 700;

	&.excluded {
		font-weight: 700;
		background-color: white;
		color: #EB6156;
	}

	&.included {
		font-weight: 700;
		background-color: white;
		color: $mainColor;
	}

	&.hover-excluded {
		font-weight: 700;
		background-color: #EB6156;
		color: white;
	}

	&.hover {
		font-weight: 700;
		background-color: $mainColor;
		color: white;
	}
}
