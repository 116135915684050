.plan-price {
	font-size: 1.8em;
	font-weight: bold;
	margin-top: 10px;

	.plan-yearly-price {
		font-size: 0.8em;
		color: #bbbbbb;
		font-weight: normal;
	}
}

.plan-description {
	font-size: 0.9rem;
	color: #666;
	margin-top: 10px;
	font-weight: 700;
}

.plan-label {
	margin: 0;
	color: $mainColor;
	font-size: 2em;

	@media (min-width: $desktop) {
		font-size: 1.7em;
	}
}

.mobile-pricing-table {
	display: flex;
	margin-top: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	gap: 20px;
	width: 100%;

	.main-features {
		font-weight: 700;
	}

	.plan-column {
		flex: 1;
		box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
			0 0px 0 1px rgba(10, 10, 10, 0.02);
		border-radius: 10px;
		margin-bottom: 20px;
		padding: 20px;
		min-width: 100%;
		font-size: 0.8em;

		@media (min-width: $desktop) {
			width: 100%;
			max-width: 800px;
			min-width: 385px;
			font-size: 1em;
		}

		&.is-active {
			background-color: rgba($yellowMI, 0.1);
		}

		.not-available {
			opacity: 0.5;
		}

		.plan-header {
			border-bottom: 1px solid #ccc;
			padding-bottom: 20px;
			margin-bottom: 20px;
			// height: 176px;

			&.clickable:hover {
				cursor: pointer;
				font-weight: bold;

				h2 {
					font-weight: bold;
				}

				.plan-description,
				.plan-price {
					color: $mainColor;
				}

				.plan-yearly-price {
					color: $lightBlue !important;
				}
			}

			.plan-target {
				// display: flex;
				// justify-content: center;
				align-items: center;
				color: black;
				margin-bottom: 5px;
				background-color: $noInteractionColor;
				border-radius: 8px;
				padding: 5px;
				white-space: nowrap;
				font-size: 1.2em;
			}
		}

		.plan-features {
			.feature-row {
				display: flex;
				align-items: center;
				font-size: 1.2em;
				margin-bottom: 10px;

				.feature-limit {
					margin-left: auto;
					display: flex;
					align-items: center;

					.fa-infinity {
						font-size: 1.2em;
						color: #333;
					}
				}

				.fa-icon {
					margin-right: 5px;
				}
			}
		}

		h3 {
			font-size: 1.2em;
			color: #333;
			margin: 20px 0;
		}

		.button {
			display: flex;
			font-weight: 700;
		}

		// Toggle tools button on mobile
		.toggle-tools {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: $lightBlue;
			border: none;
			padding: 10px;
			width: 100%;
			cursor: pointer;
			font-size: 16px;
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: $borderRadius;

			.fa-chevron-down,
			.fa-chevron-up {
				margin-left: 10px;
			}
		}
	}
}

.desktop-pricing-table-container {
	display: none;

	@media (min-width: $desktop) {
		display: block;
	}
}

.desktop-pricing-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;

	// rounded angle on first and last row for each column / plan
	thead {
		tr:first-child {
			th {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}
	}

	tfoot {
		tr:last-child {
			td {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}

	thead tr,
	tbody tr,
	tfoot tr {
		border-top: 1px solid #ccc;
	}

	tr:first-child {
		border-top: none;
	}


	th,
	td {
		border: none;
		padding: 10px;
		vertical-align: top;

		&.centered {
			text-align: center !important;
		}

		&.is-active {
			background-color: $veryLightBlue;
		}
	}

	thead {
		th.plan-header-desktop {
			&.clickable {
				cursor: pointer;

				&:hover {
					font-weight: bold;
					color: $mainColor;

					.plan-description,
					.plan-price {
						color: $mainColor;
					}

					.plan-yearly-price {
						color: $lightBlue !important;
					}
				}
			}

			.plan-target {
				background-color: $noInteractionColor;
				color: dark;
				border-radius: 8px;
				padding: 5px;
				font-size: 0.8em;
				margin-bottom: 5px;
				display: inline-block;
				white-space: nowrap;
			}

			.plan-price {
				margin-top: 10px;

				.plan-yearly-price {
					font-size: 0.8em;
					color: #999;
				}
			}

			.plan-description {
				font-size: 0.8em;
				color: #666;
				margin-top: 10px;
			}
		}
	}

	.feature-name {
		text-align: left;
	}

	.section-title {
		font-weight: bold;
	}
}

.not-available {
	opacity: 0.6;
}

.has-text-danger {
	color: $redMI !important;
}

.has-text-success {
	color: #0c0 !important;
}
